<template>
  <div>
    <h3>
      {{ $t("campany.campaignUsers") }}
      <span class="text-muted">{{ count }}</span>
    </h3>
    <p class="text-muted">{{ $t("campaignsUserText") }}</p>
    <Alert :alert="alert" class="mt-2" />
    <BaseTable
      i18n
      :loading="isLoading"
      :items="items"
      :count="count"
      :limit="10"
      @change:search="SET_SEARCH"
      :page="page"
      @change:page="SET_PAGINATION"
      :searchPlaceholder="$t('searchCampaingText')"
      :pages="pages"
      :headers="headers"
      noCaption
      :noSearch="true"
    >
      <template #fullname="{ item }">
        <strong>
          <font-awesome-icon :icon="faUser" />

          {{ item.name }} {{ item.surname }}</strong
        >
        <div>
          <small class="text-muted">
            <font-awesome-icon :icon="faCalendar" />

            {{ setDate(item.createdDate) }}</small
          >
        </div>
      </template>
      <template #contact="{ item }">
        <strong>
          <font-awesome-icon :icon="faEnvelope" />

          {{ item.email }}</strong
        >
        <div>
          <small class="text-muted">
            <font-awesome-icon :icon="faPhone" />

            {{ item.phoneNo }}</small
          >
        </div>
      </template>
      <template #taxYear="{ item }">
        {{ item.taxYear }}
      </template>
      <template #isActive="{ item }">
        <div class="text-right text-md-left">
          <div class="position-relative">
            <small
              :class="`w-100 badge badge-soft-${
                item.isActive ? 'success' : 'danger'
              }`"
              >{{ $t(item.isActive ? $t("active") : $t("notActive")) }}</small
            >
          </div>
        </div></template
      >
      <template #status="{ item }">
        <div class="text-right text-md-left">
          <div class="position-relative">
            <small
              :class="`w-100 badge badge-soft-${userStatus(item.status).class}`"
              >{{ $t(userStatus(item.status).text) }}</small
            >
          </div>
        </div></template
      >
    </BaseTable>
  </div>
</template>

<script>
import BaseTable from "../../base/Table/index.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faEye,
  faPhone,
  faEnvelope,
  faCalendar,
  faUser,
  faEyeSlash,
  faPencil,
  faTrash,
  faGifts,
} from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import Alert from "../../base/alert/Alert.vue";
import { getCampaignUsers } from "../../../services/modules/Campaign";

const { required } = require("vuelidate/lib/validators");

export default {
  components: {
    BaseTable,
    FontAwesomeIcon,
    Alert,
  },
  data() {
    return {
      faEye,
      faEyeSlash,
      faPhone,
      faUser,
      faCalendar,
      faEnvelope,
      faPencil,
      faTrash,
      faGifts,
      search: "",
      status: -1,
      statusList: [
        { value: -1, text: "allStatus" },
        { value: 0, text: "waiting" },
        { value: 1, text: "reviewing" },
        { value: 2, text: "completed" },
      ],
      headers: [
        { title: "fullname", value: "fullname", col: 3, md: 3, lg: 3 },
        { title: "contact", value: "contact", col: 3, md: 3, lg: 3 },
        { title: "taxYear", value: "taxYear", col: 3, md: 3, lg: 2 },
        { title: "campany.taxStatus", value: "status", col: 3, md: 3, lg: 2 },
        { title: "status", value: "isActive", col: 3, md: 3, lg: 2 },
      ],
      count: 0,
      page: 1,
      items: [],
      limit: 10,
      isLoading: true,
      activeUpdateStatus: -1,
      campaignsShowModal: false,
      loading: false,
      form: {
        title: "",
        code: "",
        assignedUserName: "",
        discountType: 1,
        discount: "",
        description: "",
        expireDate: null,
        unrestricted: true,
        isActive: true,
      },
      passEye: false,
      roles: [
        {
          title: "Admin",
          value: "Admin",
        },
        {
          title: "Manager",
          value: "Manager",
        },
      ],
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      campaignId: null,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: {
        required,
      },
      code: {
        required,
      },
      assignedUserName: {
        required,
      },
      discount: {
        required,
      },
      description: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["activeUser"]),
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  watch: {
    "form.unrestricted"(val) {
      if (val) {
        this.form.expireDate = null;
      }
    },
  },
  methods: {
    userStatus(val) {
      return val === 0
        ? { class: "info", text: "waiting" }
        : val === 1
        ? { class: "warning", text: "reviewing" }
        : val === 2
        ? { class: "success", text: "completed" }
        : val === 4
        ? { class: "secondary", text: "pendingConfirmation" }
        : val === 5
        ? { class: "primary", text: "transmittedFa" }
        : { class: "danger", text: "canceled" };
    },
    changeRole(val) {
      this.form.role = val;
    },
    SET_STATUS_FILTER() {
      this.page = 1;
      this.$router.push({
        query: { ...this.$route.query, page: 1, status: this.status },
      });
      this.getCampaignUserList();
    },
    SET_SEARCH(search) {
      this.search = search;
      this.page = 1;
      this.$router.push({ query: { ...this.$route.query, search, page: 1 } });
      this.getCampaignUserList(this.search, this.page, this.limit);
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.push({ query: { ...this.$route.query, page } });
      this.getCampaignUserList(this.search, this.page, this.limit);
    },
    async getCampaignUserList() {
      this.isLoading = true;
      const response = await getCampaignUsers(
        this.page,
        this.limit,
        this.$route.params.id
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      } else {
        this.items = [];
        this.count = 0;
      }
      this.isLoading = false;
    },
    setDate(date) {
      return new Date(date + "+00:00").toLocaleString(this.$i18n.locale, {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  mounted() {
    const q = this.$route.query;
    if (!isNaN(q.page)) this.page = Number(q.page);
    this.getCampaignUserList();
  },
};
</script>

<style lang="scss">
.account-settings {
  .lang-select-box {
    border-radius: 20px;
    border: 2px solid #01295f;
    padding: 0 1rem;
  }
}
.status-option-dropdown {
  position: absolute;
  top: 0px;
  width: 100%;
  background: white;
  z-index: 11;
  &__button {
    cursor: pointer;
  }
}
.password-icon {
  position: relative;

  .input-icon {
    position: absolute;
    top: 60%;
    right: 1.2rem;
    color: #01295f;
  }
}
.phone-form {
  .area-code {
    position: absolute;
    top: 52%;
    left: 20px;
  }

  .phone-input {
    position: relative;

    input {
      padding-left: 70px;
    }
  }
}

#campany-form {
  .radio-button {
    border: 2px solid #161640;
    padding: 0px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    flex-direction: row-reverse;

    input {
      height: 22px;
      width: 22px;

      &:focus {
        box-shadow: none;
      }
    }

    input[type="radio"]:checked ~ label,
    input[type="checkbox"]:checked ~ label {
      color: rgb(87, 198, 255);
    }

    input[type="radio"]:checked ~ .radio-button,
    input[type="checkbox"]:checked ~ .radio-button {
      border: 1px solid red;
    }

    input[type="radio"]:checked,
    input[type="checkbox"]:checked {
      position: relative;

      &::before {
        content: "\2713";
        position: absolute;
        background-color: rgb(87, 198, 255);
        color: white;
        height: 22px;
        width: 22px;
        border-radius: 50rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    label {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      height: 36px;
    }
  }
}

.actions-button {
  span {
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .married-form-margin {
    .active-radio-button {
      margin-bottom: 20px;
    }
  }
}
</style>