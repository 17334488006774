<template>
  <CampaignUsers></CampaignUsers>
</template>

<script>
import CampaignUsers from "@/components/admin/campaigns/Users.vue";

export default {
  components: {
    CampaignUsers,
  },
};
</script>

<style>
</style>